<template>
  <b-modal
    :visible="isModalActive"
    title="จัดการราคา"
    ok-title="Accept"
    centered
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-row>
      <b-col
        cols="2"
        class="mb-2"
      >
        <b-card-text style="fontSize: 16px;">
          {{ $t("Price") }}
        </b-card-text>
      </b-col>
      <b-col
        cols="10"
        class="mb-2"
      >
        <b-form-input
          id="note"
          v-model="data.priceSum"
          :disabled="!priceManage"
          class="form-control"
          :placeholder="$t('Price')"
        />
      </b-col>
      <b-col
        cols="2"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="priceManage"
          class="custom-control-primary"
          name="check-button"
          switch
        />
      </b-col>
      <b-col
        cols="10"
        class="mb-2"
      >
        <span>{{ $t('Manage Price By Admin') }}</span>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          class="float-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="comfirmBooking()"
          >
            {{ $t('Accept') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class=""
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t('Close') }}
          </b-button>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BCardText,
    BFormInput,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      priceManage: false,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          // const {
          //   _id, apiWebScraping, apiQRcode, apiIBanking, apiMobileBanking, apiCreditCard, apiATM,
          // } = this.data
          // this.dataId = _id
          // this.apiWebScraping = apiWebScraping
          // this.apiQRcode = apiQRcode
          // this.apiIBanking = apiIBanking
          // this.apiMobileBanking = apiMobileBanking
          // this.apiCreditCard = apiCreditCard
          // this.apiATM = apiATM
        }
      }
    },
  },
  methods: {
    comfirmBooking() {
      const obj = {
        ...this.data,
        priceManage: this.priceManage,
      }
      store
        .dispatch(`${this.storeModuleName}/confirmPayment`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          // eslint-disable-next-line no-unused-vars
          this.$router.push({ path: '/hero/list/service/queue' })
          this.show = false
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    initValues() {
      this.dataId = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
