<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-1">
          <b-row>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Member") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <v-select
                id="register-respDataMember"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="respDataMember"
                :reduce="(respDataMember) => respDataMember"
                :filterable="false"
                @search="onSearchMember"
                @input="selectedMember($event)"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >

                  {{ option.firstName + ' ' + option.lastName }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.firstName + ' ' + option.lastName }}
                </template>
                <div
                  slot="list-footer"
                  class="m-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="currentPageMember <= 1 ? true :false"
                        @click="nextPageMember(currentPageMember -1)"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        disabled
                      >
                        {{ currentPageMember }}
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="(currentPageMember * perPageMember) >= queriedItemsMember ? true :false"
                        @click="nextPageMember(currentPageMember + 1)"
                      >
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
              </v-select>
            </b-col>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Partner") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <v-select
                id="register-respDataPartner"
                v-model="partnerProfileId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="firstName"
                :options="respDataPartner"
                :reduce="(respDataPartner) => respDataPartner"
                :filterable="false"
                @search="onSearchPartner"
                @input="queryServices"
              >
                <div
                  slot="list-footer"
                  class="m-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="currentPagePartner <= 1 ? true :false"
                        @click="nextPagePartner(currentPagePartner -1)"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        disabled
                      >
                        {{ currentPagePartner }}
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="(currentPagePartner * perPagePartner) >= queriedItemsPartner ? true :false"
                        @click="nextPagePartner(currentPagePartner + 1)"
                      >
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                  </b-row>

                </div>
              </v-select>
            </b-col>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Services") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <v-select
                id="register-services"
                v-model="serviceId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="firstName"
                :options="respDataServices"
                :reduce="(respDataServices) => respDataServices"
                @input="selectedService($event)"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >

                  {{ showFromCurrentLanguage(option.name) }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ showFromCurrentLanguage(option.name) }}
                </template>
              </v-select>
            </b-col>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Day") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <div class="d-flex align-items-center justify-content-end">
                <flat-pickr
                  v-model="day"
                  class="form-control"
                  :config="configs"
                />
              </div>
            </b-col>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Period") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <v-select
                id="register-services"
                v-model="timeId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="respDataTime"
                :reduce="(respDataTime) => respDataTime"
                @input="selectedPeriod($event)"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >

                  {{ `${option.time} ${$t('Empty Queue')} ${option.queue} ${$t('Queue')}` }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ `${option.time} ${$t('Empty Queue')} ${option.queue} ${$t('Queue')}` }}
                </template>
              </v-select>
            </b-col>
            <b-col
              v-for="(item, index) in respDataMoreOptions"
              :key="index"
              cols="12"
              class="mb-2"
            >
              <b-row v-if="timeId">
                <b-col cols="2">
                  <b-card-text style="fontSize: 16px;">
                    {{ item.name }}
                  </b-card-text>
                </b-col>
                <b-col cols="10">
                  <b-row>
                    <b-form-checkbox
                      v-for="(item2 , index2) in item.attribute"
                      :key="index2"
                      class="m-1"
                      @input="selectedValue($event,item2, index, item)"
                    >
                      {{ item2.name }}
                    </b-form-checkbox>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="2"
              class="mb-2"
            >
              <b-card-text style="fontSize: 16px;">
                {{ $t("Note") }}
              </b-card-text>
            </b-col>
            <b-col
              cols="10"
              class="mb-2"
            >
              <b-form-textarea
                id="note"
                v-model="note"
                class="form-control"
                :placeholder="$t('Note')"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-card-text>{{ $t("Images") }} {{ $t("All") }}
                  {{ images.length }} {{ $t("Images") }}</b-card-text>
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-row>
                      <b-col
                        v-for="(item, index) in images"
                        :key="index"
                        cols="3"
                      >
                        <b-img
                          fluid-grow
                          :src="item"
                          rounded
                          class="mr-1 mb-1 imgs-show"
                          height="120"
                        />
                        <b-button
                          variant="danger"
                          class="btn-remove"
                          @click="removeImages(index)"
                        >
                          {{ $t("Delete") }} ({{ index+1 }})
                        </b-button>
                      </b-col>
                    </b-row>

                    <!--/ avatar -->
                  </b-media-aside>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputImages.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputImages"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImagesRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImages"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t("Allowed") }} JPG, GIF {{ $t("or") }} PNG.
                      {{ $t("Max size of") }} 300KB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              v-if="partnerProfileId && partnerProfileId.config.called"
              cols="12"
            >
              <GmapMap
                ref="mapRef"
                :style="'height:500px'"
                :center="dataCenter"
                :zoom="zoom"
                @click="drawInMap"
              >
                <GmapMarker
                  :draggable="true"
                  :position="dataCenter"
                  :clickable="true"
                  @dragend="setLocal"
                />
              </GmapMap>
            </b-col>
            <b-row
              v-if="partnerProfileId && partnerProfileId.config.called"
              class="mt-2 ml-2 align-items-center"
            >
              <b-col cols="6">
                <b-form-group
                  :label="$t('Lat')"
                  label-for="register-lat"
                >
                  <b-form-input
                    v-model="lat"
                    name="register-lat"
                    :placeholder="$t('Lat')"
                    @input="setCenter($event, 'lat')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Lng')"
                  label-for="register-lng"
                >
                  <b-form-input
                    v-model="lng"
                    name="register-lng"
                    :placeholder="$t('Lng')"
                    @input="setCenter($event, 'lng')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-col
              v-if="partnerProfileId"
              cols="12"
              class="text-right"
            >
              <b-button
                v-if="partnerProfileId.config.goFind"
                variant="primary"
                class="mr-2"
                @click="getDetailToPayment('goFind')"
              >
                {{ $t('At Shop') }}
              </b-button>
              <b-button
                v-if="partnerProfileId.config.called"
                variant="primary"
                class="mr-2"
                @click="getDetailToPayment('called')"
              >
                {{ $t('Call Home') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BOverlay, BFormGroup, BFormInput, BButton,
  BFormCheckbox, BFormTextarea,
  BMedia,
  // BMediaAside,
  BMediaBody,
  // BLink,
  BImg,
  BMediaAside,
  BFormFile,
  // BFormRadio,
  // BTable,
  // BPagination,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import perPageOptions from '@/perPageOptions'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/store/services/hero/bookQueue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import debounce from 'debounce'
import flatPickr from 'vue-flatpickr-component'
import Modal from './Modal.vue'
// eslint-disable-next-line import/extensions
// import { Thai } from 'flatpickr/dist/l10n/th.js'

const STORE_MODULE_NAME = 'bookQueue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BLink,
    BImg,
    BMediaAside,
    BFormFile,
    // BFormRadio,
    flatPickr,
    // BTable,
    // BPagination,
    // flatPickr
    vSelect,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      day: this.formatDate(new Date(Date.now())),
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
      avatarText,
      partnerLocation: {
        type: 'Point',
        coordinates: [103.652653, 16.056734],
      },
      dataCenter: { lat: 16.056734, lng: 103.652653 },
      lat: 16.056734,
      lng: 103.652653,
      zoom: 12,
      userId: '',
      userInfoId: '',
      searchMember: '',
      currentPageMember: 1,
      perPageMember: 10,
      partnerProfileId: '',
      partnerId: '',
      searchPartner: '',
      currentPagePartner: 1,
      perPagePartner: 10,
      serviceId: '',
      configs: {
        minDate: 'today',
        enableTime: false,
        dateFormat: 'l,Y-m-d',
        // locale: Thai,
      },
      timeId: '',
      attr: '',
      listTypeOptionSelected: [],
      optionSelected: [],
      startTime: '',
      endTime: '',
      date: '',
      priceSum: 0,
      sale: 0,
      location: { type: 'Point', coordinates: [0, 0] },
      distance: '0.00',
      travelPartnerPrice: 0,
      bookingDate: Date.now(),
      note: '',
      images: [],
      isModalActive: false,
      modalData: {},
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'id', label: this.$t('ID'), sortable: false },
        { key: 'title', label: this.$t('Title'), sortable: false },
        { key: 'member', label: this.$t('Member'), sortable: false },
        { key: 'partner', label: this.$t('Partner'), sortable: false },
        { key: 'start', label: this.$t('Start'), sortable: false },
        { key: 'end', label: this.$t('End'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respDataMember() {
      return store.state[STORE_MODULE_NAME].respDataMember != null ? store.state[STORE_MODULE_NAME].respDataMember.data : []
    },
    queriedItemsMember() {
      return store.state[STORE_MODULE_NAME].respDataMember != null ? store.state[STORE_MODULE_NAME].respDataMember.max : 0
    },
    respDataPartner() {
      return store.state[STORE_MODULE_NAME].respDataPartner != null ? store.state[STORE_MODULE_NAME].respDataPartner.data : []
    },
    queriedItemsPartner() {
      return store.state[STORE_MODULE_NAME].respDataPartner != null ? store.state[STORE_MODULE_NAME].respDataPartner.max : 0
    },
    respDataServices() {
      return store.state[STORE_MODULE_NAME].respDataServices != null ? store.state[STORE_MODULE_NAME].respDataServices.data : []
    },
    respDataTime() {
      return store.state[STORE_MODULE_NAME].respDataTime != null ? store.state[STORE_MODULE_NAME].respDataTime.data : []
    },
    respDataMoreOptions() {
      return store.state[STORE_MODULE_NAME].respDataMoreOptions != null ? store.state[STORE_MODULE_NAME].respDataMoreOptions.data : []
    },
  },
  watch: {
    day(newData) {
      if (newData) {
        const _day = newData.split(',')[0]
        const _date = newData.split(',')[1]
        const reverseDate = _date.split('-')
        this.bookingDate = new Date(_date).getTime()
        this.date = `${reverseDate[2]}-${reverseDate[1]}-${reverseDate[0]}`
        const obj = {
          profilePartnerId: this.partnerProfileId._id,
          queueServiceId: this.serviceId._id,
          day: _day,
          date: _date,
        }
        store
          .dispatch(`${STORE_MODULE_NAME}/getTimeServiceOnProfile`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })

        store
          .dispatch(`${STORE_MODULE_NAME}/getMoreOption`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.listTypeOptionSelected = []
            if (result.data.data.length > 0) {
              for (let index = 0; index < result.data.data.length; index += 1) {
                const element = result.data.data[index]
                this.listTypeOptionSelected.push({
                  name: element.name,
                  option: element._id,
                  typeMultipleOption: element.typeMultipleOption,
                  itemSelected: element.itemSelected,
                  attribute: [],
                })
              }
            }
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const objMember = {
      currentPage: this.currentPageMember,
      pageSize: this.perPageMember,
      searchQuery: this.searchMember,
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getMember`, objMember)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    const objPartner = {
      currentPage: this.currentPagePartner,
      pageSize: this.perPagePartner,
      searchQuery: this.searchPartner,
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getPartner`, objPartner)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.onSearchMember = debounce(this.onSearchMember, 500)
    this.onSearchPartner = debounce(this.onSearchPartner, 500)
    // this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    removeImages(index) {
      this.images.splice(index, 1)
    },
    inputImagesRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.images.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputImages.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImages() {
      this.images = []
    },
    getDetailToPayment(value) {
      this.show = true
      const dateFormat = this.date.split('-')
      const obj = {
        selectIdTime: this.timeId.id,
        idService: this.serviceId._id,
        idProfile: this.partnerProfileId._id,
        selectDate: `${dateFormat[2]}-${dateFormat[1]}-${dateFormat[0]}`,
        lat: this.lat,
        lng: this.lng,
        useConfig: value,
        optionServices: this.optionSelected,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getDetailToPayment`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const { data } = result.data
          this.bookingDate = data.bookingDate
          this.price = data.price
          this.travelPartnerPrice = data.travelPartnerPrice
          this.distance = data.distance
          this.priceSum = data.priceSum
          this.modalData = {
            userInfo: this.userInfoId,
            userId: this.userId,
            partnerUserId: this.partnerId,
            profilePartnerId: this.partnerProfileId._id,
            queueServiceId: this.serviceId._id,
            queueWorkingPeriodId: this.timeId.id,
            paymentMethod: 'cash',
            paymentStatus: 'paid',
            slip: '',
            imageSlip: {
              image: '',
              type: 'image/png',
            },
            bookingDate: this.bookingDate,
            date: this.date,
            startTime: this.startTime,
            endTime: this.endTime,
            price: this.price,
            sale: this.sale,
            priceSum: this.priceSum, // this.priceSum,
            location: { type: 'Point', coordinates: [this.lng, this.lat] },
            distance: this.distance,
            travelPartnerPrice: this.travelPartnerPrice,
            description: this.note,
            url: '',
            useConfig: value,
            optionServices: this.listTypeOptionSelected,
            couponId: '',
            images: this.images,
          }
          this.show = false
          this.isModalActive = true
          // this.comfirmBooking(value)
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    selectedMember(value) {
      this.userInfoId = value._id
      this.userId = value.userId._id
    },
    formatTime(time) {
      if (time < 10) {
        return `0${time}`
      }
      return time
    },
    selectedPeriod(value) {
      this.startTime = `${this.formatTime(value.openTime.hour)}:${this.formatTime(value.openTime.minute)}:00`
      this.endTime = `${this.formatTime(value.closeTime.hour)}:${this.formatTime(value.closeTime.minute)}:00`
    },
    comfirmBooking(value) {
      const obj = {
        userInfo: this.userInfoId,
        userId: this.userId,
        partnerUserId: this.partnerId,
        profilePartnerId: this.partnerProfileId._id,
        queueServiceId: this.serviceId._id,
        queueWorkingPeriodId: this.timeId.id,
        paymentMethod: 'cash',
        paymentStatus: 'paid',
        slip: '',
        imageSlip: {
          image: '',
          type: 'image/png',
        },
        bookingDate: this.bookingDate,
        date: this.date,
        startTime: this.startTime,
        endTime: this.endTime,
        price: this.price,
        sale: this.sale,
        priceSum: this.priceSum, // this.priceSum,
        location: { type: 'Point', coordinates: [this.lng, this.lat] },
        distance: this.distance,
        travelPartnerPrice: this.travelPartnerPrice,
        description: this.note,
        url: '',
        useConfig: value,
        optionServices: this.listTypeOptionSelected,
        couponId: '',
        images: this.images,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/confirmPayment`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          // eslint-disable-next-line no-unused-vars
          this.$router.push({ path: '/hero/list/service/queue' })
          this.show = false
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    // eslint-disable-next-line no-unused-vars
    selectedValue(evt, value, index, option) {
      if (evt) {
        this.optionSelected.push(value._id)
        console.log(this.optionSelected)
        this.listTypeOptionSelected[index].attribute.push(value._id)
        this.priceSum += Number(value.price)
      } else {
        const isIndex = element => element === value._id
        const _index = this.listTypeOptionSelected[index].attribute.findIndex(isIndex)
        const _index2 = this.optionSelected.findIndex(isIndex)
        this.listTypeOptionSelected[index].attribute.splice(_index, 1)
        this.optionSelected.splice(_index2, 1)
        console.log(this.optionSelected)
        this.priceSum -= Number(value.price)
      }
    },
    selectedService(value) {
      this.price = value.price
      this.priceSum = value.price
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    queryServices() {
      this.partnerId = this.partnerProfileId.staff[0].partnerUserId
      this.serviceId = ''
      store
        .dispatch(`${STORE_MODULE_NAME}/getServices`, this.partnerProfileId._id)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    onSearchPartner(query) {
      this.searchPartner = query
      this.currentPagePartner = 1
      const obj = {
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPagePartner(page) {
      this.currentPagePartner = page
      this.profilePartnerId = ''
      const obj = {
        currentPage: this.currentPagePartner,
        pageSize: this.perPagePartner,
        searchQuery: this.searchPartner,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getPartner`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPageMember(page) {
      this.currentPageMember = page
      this.memberId = ''
      const obj = {
        currentPage: this.currentPageMember,
        pageSize: this.perPageMember,
        searchQuery: this.searchMember,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getMember`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    onSearchMember(query) {
      this.searchMember = query
      this.currentPageMember = 1
      const obj = {
        currentPage: this.currentPageMember,
        pageSize: this.perPageMember,
        searchQuery: this.searchMember,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getMember`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    typing(value) {
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        day: this.day,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    setCenter(value, type) {
      if (type === 'lat') {
        this.dataCenter.lat = parseFloat(value)
        this.lat = parseFloat(value)
        this.partnerLocation.coordinates[1] = parseFloat(value)
      } else {
        this.dataCenter.lng = parseFloat(value)
        this.lng = parseFloat(value)
        this.partnerLocation.coordinates[0] = parseFloat(value)
      }
    },
    drawInMap(location) {
      // กดแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
      this.lat = lat
      this.lng = lng
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo({ lat, lng })
      })
    },
    setLocal(location) {
      // ลากแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
      this.lat = lat
      this.lng = lng
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
