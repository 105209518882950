import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataMember: null,
    respDataPartner: null,
    respDataServices: null,
    respDataTime: null,
    respDataMoreOptions: null,
  },
  getters: {
  },
  mutations: {
    GET_MEMBER(state, payload) {
      state.respDataMember = payload
    },
    GET_PARTNER(state, payload) {
      state.respDataPartner = payload
    },
    GET_SERVICES(state, payload) {
      state.respDataServices = payload
    },
    GET_TIME(state, payload) {
      state.respDataTime = payload
    },
    GET_MORE_OPTIONS(state, payload) {
      state.respDataMoreOptions = payload
    },
  },
  actions: {
    getMember({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getMember?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_MEMBER', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPartner({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getPartner?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_PARTNER', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getServices({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getServices?partnerProfileId=${item}`)
          .then(response => {
            commit('GET_SERVICES', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTimeServiceOnProfile({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getTimeServiceOnProfile?profilePartnerId=${item.profilePartnerId}&queueServiceId=${item.queueServiceId}&day=${item.day}&date=${item.date}`)
          .then(response => {
            commit('GET_TIME', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getMoreOption({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getMoreOption?idProfile=${item.profilePartnerId}&idService=${item.queueServiceId}`)
          .then(response => {
            commit('GET_MORE_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    confirmPayment({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/bookQueue/confirmPayment?lang=th', item)
          .then(response => {
            // commit('GET_MORE_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getDetailToPayment({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/bookQueue/getDetailToPayment/${item.selectIdTime}/${item.idService}/${item.idProfile}/${item.selectDate}?lat=${item.lat}&lng=${item.lng}&useConfig=${item.useConfig}&optionServices=${item.optionServices}&lang=th`)
          .then(response => {
            // commit('GET_MORE_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
